<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcUserPreferences }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-header>
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>{{ translations.tcPreferences }}</h2>
            <ToggleArrow
              v-if="toggle == true"
              @click="Section1 = !Section1"
              section="Section1"
              :status="Section1"
            ></ToggleArrow>
          </header>
          <div v-if="Section1">
            <b-row class="mb-5">
              <b-col sm="3" class="font-style-3">&nbsp;</b-col>
              <b-col sm="6" class="font-style-3">&nbsp;</b-col>
              <b-col sm="3" class="font-style-3">{{ translations.tcEdit }}</b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col sm="3" class="font-style-3">
                <strong>{{ translations.tcLoginEmail }}:</strong>
              </b-col>
              <b-col sm="6" class="font-style-3">
                {{
                  !(
                    individualCommunicationPreferencesData.loginEmail.constructor === Object &&
                    Object.keys(individualCommunicationPreferencesData.loginEmail).length === 0
                  )
                    ? individualCommunicationPreferencesData.loginEmail.usr_login_id
                    : this.translations.tcNoEmailOnFile
                }}
              </b-col>
              <b-col sm="3" class="font-style-3">
                <div @click="editLoginEmail" v-if="okToEditProfile">
                  <ServiceEdit />
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col sm="3" class="font-style-3">
                <strong>{{ translations.tcPreferredEmail }}:</strong>
              </b-col>
              <b-col sm="6" class="font-style-3">{{
                !!preferredEmail ? preferredEmail.email_address : translations.tcNoEmailOnFile
              }}</b-col>
              <b-col sm="3" class="font-style-3">
                <div @click="emailStoreAndRedirect" v-if="okToEditProfile">
                  <ServiceEdit />
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col sm="3" class="font-style-3">
                <strong>{{ translations.tcPreferredLanguage }}:</strong>
              </b-col>
              <b-col sm="6" class="font-style-3">
                {{ preferredLanguage }}
              </b-col>
              <b-col sm="3" class="font-style-3">
                <div @click="editPreferredLanguage" v-if="okToEditProfile">
                  <ServiceEdit />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </section>
    </page-body>
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'user-preferences',
  mixins: [translationMixin],
  data() {
    return {
      backJumpObj: {
        link: '#',
        location: '',
      },
      preferredLanguage: '',
      Section1: true,
      toggle: true,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      getIndividualCommPreferences: 'membership/getIndividualCommPreferences',
      getIndividualCommunications: 'membership/getIndividualCommunications',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAddress: 'user/setSelectedAddress',
      setSelectedEmail: 'user/setSelectedEmail',
    }),
    updateCamp(value) {
      this.camp = value
    },
    async pageLoad() {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('back-jump'),
        this.getComponentTranslations('common.church-languages'),
        await this.loadIndividualProfile(),
        await this.getIndividualCommPreferences(this.userSelectedIndividualKey),
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        this.updateDynamicText()

        this.backJumpObj.location = this.individualProfile.formal_name
        this.backJumpObj.link = this.backLink
        this.setLoadingStatus(false)
      })
    },
    async emailStoreAndRedirect() {
      this.setSelectedEmail(this.preferredEmail)
      this.$router.push({ name: 'edit-member-preferred-email' })
    },
    async editLoginEmail() {
      this.$router.push({ name: 'edit-member-login-email' })
    },
    async editPreferredLanguage() {
      this.$router.push({ name: 'edit-preferred-language' })
    },
    updateDynamicText() {
      if (!this.translations.common || !this.individualCommunicationPreferencesData) return false

      if (this.individualCommunicationPreferencesData.preferredLanguage) {
        const languageGuid = this.individualCommunicationPreferencesData.preferredLanguage.lnt_key || ''
        const languageText = this.translations.common['church-languages'].hasOwnProperty(languageGuid) ?
          this.translations.common['church-languages'][languageGuid] :
          this.individualCommunicationPreferencesData.preferredLanguage.lnt_language

        return this.preferredLanguage = languageText
      } else {
        return this.preferredLanguage = this.translations.tcNoLanguageOnFile
      }
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      individualCommunicationPreferencesData: 'membership/individualCommunicationPreferencesData',
      individualProfile: 'membership/individualProfile',
      okToEditProfile: 'user/okToEditProfile',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    backLink() {
      return '/programs/mb/member-profile'
    },
    preferredEmail() {
      const prefEmail = this.individualCommunicationPreferencesData.memberEmails.find((e) => e.primary_email_flag === 1)
      return prefEmail ? prefEmail : null
    },
  },
  async created() {
    if (!this.userSelectedIndividualKey) {
      this.$router.push('/programs/mb/membership/')
    }
    await this.pageLoad()
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceEdit,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.service-times-presentations {
  header h1 {
    padding-bottom: 40px;
    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }
  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;
    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  strong {
    text-transform: uppercase;
  }
  svg {
    cursor: pointer;
  }
  section {
    margin-bottom: 40px;
    .p-4 {
      padding: 40px 30px 20px 30px !important;
      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }
    header {
      display: flex;
      h2 {
        flex: 1 1 auto;
        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }
      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }
    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .service-times,
  .pres-dates {
    margin-top: 35px;
  }
  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;
      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}
</style>
